@import '../../../t1variables.scss';
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

#netSheet, #reverseNetSheet {
    .group-header {
        font-weight: bold;
        font-size: 1.2em;
        background-color: $light;
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
    }

    .accordion-button:not(.collapsed) {
        color: inherit;
        background-color: $light;
    }

    .up-down-percent {
        color: $primary;
    }

    .form-label label {
        line-height: $line-height-sm;
        vertical-align: bottom;
    }
}