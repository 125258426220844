@import '../../t1variables.scss';

.quick-tools {
    position: sticky !important;
    top:0;

    a {
        color: $primary-on-light;
        font-weight: 500;
    }
}

.tool-icon {
    height: 32px;
}