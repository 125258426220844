.region-btn-link {
    font-size: inherit;
    line-height: inherit;
}

#regionSwitcherModal {
    font-size: initial;
    line-height: initial;

    .brand-external-link svg {
        width: .8em;
    }

    .group-header {
        font-weight: initial;
        font-size: initial;
        background-color: initial;
        border-top-left-radius: initial;
        border-top-right-radius: initial;
    }

    .accordion-button:not(.collapsed) {
        color: inherit !important;
        background-color: initial !important;
    }

    .form-check:first-child, .other-county:first-child {
        border-top: none !important;
    }
}
