@import '../../../t1variables.scss';

.devBrandMenuContainer {
    width: 200px;
    position: absolute;
    z-index: 1080;
    left: 0;
    bottom: 0;

    .dropdown-toggle {
        color: $primary-on-light;
    }
}