.exploreMoreMap {

    .disabled {
        pointer-events: none; /* Prevents interaction with the div */
        opacity: 0.5; /* Visually indicates that the div is disabled */
    }

    .overlay {
        position: absolute;
        z-index: 1000;
        left: 50%;
        transform: translate(-50%, 0);
        top: 85%;
    }
}
