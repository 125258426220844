@import '../../t1variables.scss';

.home-cards {

    article:hover {
        box-shadow: 0 10px 20px #adadad !important;
    }

    a {
        font-weight: 500;
    }
}
