@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$deskTopVideoHeight: 350px;
$mobileVideoHeight: 150px;

.video-cover {
    position: relative;
    height: $mobileVideoHeight;
    overflow: hidden;
    z-index: -1;
}

video {
    width: 100%;
    max-height: 100%;
}

.circleWipe.carousel-item {
    animation: 6s cubic-bezier(.25, 1, .30, 1) circle-in-left both;
}

.carousel-fade .circleWipe.carousel-item {
    opacity: 1 !important;
}

@keyframes circle-in-left {
    from {
        clip-path: circle(0% at left);
    }

    to {
        clip-path: circle(125%);
    }
}

.hero-image-spacer {
    height: 51vh;
}

.carousel-item {
    width: 100%;
    height: auto;
    aspect-ratio: 4/1;
}

.carousel-control-prev, .carousel-control-next {
    width: 1px !important;
}
.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
    outline: rgb(255,255,255, .1) !important;
}

.culture-video {
    width: 300px;
    height: 169px;
}

@include media-breakpoint-up(md) {
    .culture-video {
        width: 460px;
        height: 259px;
    }
}

@include media-breakpoint-up(lg) {
    .video-cover {
        height: $deskTopVideoHeight;
    }

    .culture-video {
        width: 760px;
        height: 428px;
    }
}

@include media-breakpoint-up(xl) {
    .culture-video {
        width: 1100px;
        height: 619px;
    }
}