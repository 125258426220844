@import '../../../t1variables.scss';

@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

#t1-nav-bar {
    background-color: $light;

    .navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    .navbar-brand img, .logoLoading {
        height: 40px;
    }

    button {
        border: none;
        font-size: .9375rem;
    }

    button:active, button:focus {
        box-shadow: none;
        outline: 0;
    }

    .dropdown-menu {
        background-color: $light;

        li {
            padding-left: 10px !important;
        }
    }

    .navbar-toggler-icon {
        margin-bottom: .18rem;
    }
}

@include media-breakpoint-up(lg) {

    #t1-nav-bar {
        background-color: inherit;
        .shrink-on-md {
            .btn.btn-link, .nav-link {
                font-size: .8rem;
            }
        }

        .dropdown-menu {
            background-color: #000000;

            li {
                padding-left: 0;
            }
        }

        .dropdown-menu > li > a.nav-link {
            color: white;
            font-weight: normal;
            text-decoration-color: $primary;
        }

        .two-column-list {
            column-count: 2;
        }

        a.navbar-brand img, .logoLoading {
            height: 65px;
        }

        .nav-item {
            padding-right: .1rem;
            padding-left: .1rem;
        }
    }
}

@include media-breakpoint-up(xl) {
    #t1-nav-bar {

        .shrink-on-md {
            .btn.btn-link, .nav-link {
                font-size: .8rem;
            }
        }

        .nav-item {
            padding-right: .2rem;
            padding-left: .2rem;
        }
    }
}


@include media-breakpoint-up(xxl) {
    #t1-nav-bar {

        .shrink-on-md {
            .btn.btn-link, .nav-link {
                font-size: .8rem;
            }
        }

        a.navbar-brand img, .logoLoading {
            height: 75px;
        }

        .nav-item {
            padding-right: .5rem;
            padding-left: .5rem;
        }
    }
}

#t1-nav-menu {
    font-family: 'Noto Sans JP';
}

@media print {
    #t1-nav-bar {
        background-color: $light;
        margin-bottom: 1rem;
    }
    #t1-nav-menu {
        display: none !important;
    }
}