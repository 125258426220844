@import '../../t1variables.scss';


.property-image {
    width: 150px;
}

.properties-list > .collapse-trigger:not(.collapsed){
    background-color: $light;
}

.properties-list > div > .collapse-trigger:not(.collapsed) {
    background-color: $light;
}

.collapse-trigger > .row:nth-of-type(1)::after {
    font-family: "Glyphicons Halflings";
    content: "−";
    float: right;
    margin-left: -35px;
}

.collapsed .collapse-trigger > .row:nth-of-type(1)::after, .collapse-trigger.collapsed > .row:nth-of-type(1)::after {
    content: "+";
    float: right;
    margin-left: -35px;
}
