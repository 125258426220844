@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css);
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

.featured-tools-slider {
    width: 85% !important;
    height: 85px;

    .slick-arrow::before {
        color: #ADADAD;
    }

    a div {
        color: black;
        font-size: .9rem;
    }

    .tool-icon {
        height: 25px;
    }
}

@include media-breakpoint-up(md) {
    .featured-tools-slider {
        height: 120px;

        a div {
            color: black;
            font-size: inherit;
        }

        .tool-icon {
            height: 36px;
        }
    }
}