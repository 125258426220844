@import '../../../t1variables.scss';
.socialLinks a {
    color: black;
}

.socialLinks a:hover, .socialLinks a:focus {
    color: $primary-on-light;
}

a.languagelink {
    font-size: .9em;
    font-weight: 600;
    color: $primary-on-light;
    text-decoration: none;
}
