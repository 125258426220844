@import './t1variables.scss';

@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

a, .btn-link {
    text-decoration: none;
}

a:hover, a:focus, .btn-link:hover, .btn-link:focus {
    text-decoration: underline;
}
.btn-link:hover {
    cursor: pointer;
}
p a {
    text-decoration: underline;
}

p a:hover, p a:focus {
    text-decoration: none;
}

a.visually-hidden-focusable {
    text-decoration: underline;
}

html, body, #root{
    height: 100%;
}

body {
    letter-spacing: .028em;
    font-size: .9375rem;
}
h1, .h1 {
    font-weight: 700;
    font-variant-caps: all-small-caps;
}

h3, .h3 {
    font-weight: 700;
    color: #6F7676;
}

h4, .h4 {
    font-weight: 400;
}

.page-content-container li {
    padding-top: .25rem;
    padding-bottom: .25rem;

}

.page-content-container p {
    line-height: $line-height-base;
}

.required:after {
    content: "*";
    color: #f4422e;
}

.form-label {
    text-align: right;
}
.form-labels-left-md .form-label {
    text-align: left;
}

.lg-react-icons {
    font-size: 30px;
}

.sm-react-icons {
    font-size: 22px;
}

.modal-iframe {
    padding: 0;
    width: 100%;
    height: 400px;
    margin: 0.5em;
    border: 0;
}

.ios-blue {
    color: #7AB6FC;
}


@include media-breakpoint-up(md) {
    .form-labels-left-md .form-label {
        text-align: right;
    }
}

@media print {
    body {
        font-size: .95rem;
    }

    .required:after {
        content: "";
    }
}
