@import '../../../t1variables.scss';

a.userProfileNav, .userProfileNav a, .userProfileNav button {
    font-size: .9em;
    font-weight: 700;
    color: $primary-on-light;
    text-decoration: none;
}

.userProfileNav a:hover, .userProfileNav button:hover {
    text-decoration: underline;
}

.userProfileNav .welcome {
    font-size: .9em;
}
