@import '../../t1variables.scss';


.plat-map, .locator-map {
    width: 100%;
}

.locator-maps {
    min-height: 516px;
}
.aspect-ratio {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75.0465%;
}

.aspect-ratio embed {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}