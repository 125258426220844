@import '../../t1variables.scss';
.blog-preview {
    article:hover {
        box-shadow: 0 10px 20px #adadad !important;
    }

    p {
        line-height: $line-height-base;
    }

    a {
        font-weight: 500;
    }
}


