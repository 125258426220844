@import './../../t1variables.scss';


.location-cards:hover {
    cursor: pointer;
    text-decoration: none;
    color: black;

    .location-card-inner-link {
        text-decoration: underline;
    }
}

.location-cards {
    color: black;
}

.team-cards span[role=button]:hover {
    .team-member-name {
        text-decoration: underline;
    }
}
