@import '../../../t1variables.scss';

#googlesearch form {
    display: none;
}

.gsc-control-cse, .gsib_a, .gsc-search-button-v2 {
    padding: 0 !important;
}
#googlesearch {
    margin-bottom: 0px !important;

    table {
        margin-bottom: 0px !important;
    }

    .gs-title {
        color: $primary;
    }

    .gs-title:hover {
        text-decoration: underline;
    }
}
#gSearchInputBox {
    border-radius: 25px;
    font-size: .8rem;
    line-height: .8rem;
    min-height: calc(1.5em + .2rem + 1px);
}

#___gcse_0 {
    width: 300px;
}

.gscb_a {
    line-height: 0 !important;
    vertical-align: middle;
}

.gsc-cursor-page {
    padding: 5px;
}

.gsc-search-button-v2 svg:hover {
    fill: $primary-on-light !important
}
