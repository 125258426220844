@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}

@include media-breakpoint-up(md) {
    .sm-box-list {
        column-count: 1;
    }

    .lg-box-list {
        column-count: 2;
    }
}

@include media-breakpoint-up(lg) {
    .sm-box-list {
        column-count: 2;
    }

    .lg-box-list {
        column-count: 4;
    }
}
