@import './../../t1variables.scss';

@media (max-width: 480px) {
    .downloadInfo {
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    .downloadInfo {
        font-size: 12px;
    }
}

.shadowbox:hover {
    filter: drop-shadow(2px 2px 2px rgba(72,78,85,.6));
    background-color: $light;
} 
    
    
