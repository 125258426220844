@media (max-width: 480px) {
    .recentInfo {
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    .recentInfo {
        font-size: 12px;
    }
}
