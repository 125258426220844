.properties-list {
    max-height: 575px;
    overflow-x: hidden;
    overflow-y: auto;
}

.card-header.collapse-trigger::after {
    font-family: "Glyphicons Halflings";
    content: "−";
    float: right;
    margin-left: -35px;
}

.card-header.collapsed .collapse-trigger::after, .card-header.collapse-trigger.collapsed::after {
    content: "+";
    float: right;
    margin-left: -35px;
}

.legend-box {
    height: 24px;
    width: 24px;
    border-radius: 2px;
    display: inline-block;
}

.legend-selected-property {
    border: 2px solid #cdcdcd;
    background-color: rgba(13, 246, 41, 0.5)
}

.legend-nearby-property {
    border: 2px solid #ed6100;
    background-color: rgba(205, 205, 205, 0.3)
}

.legend-more-property {
    border: 2px solid #000000;
    background-color: rgba(205, 205, 205, 0.3);
}

.data-border {
    border: 2px solid #f8f9fa;
    border-radius: 5px;
}

@media print {
    .data-border {
        border: none !important;
    }
}

@media (max-width: 480px) {
    .search-map {
        max-height: 475px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

@media (max-width: 768px) {
    .data-border {
        border: none !important;
    }
}